
(function ($) {
  $(".menu-trigger").click(function () {
    $(".menu-trigger").toggleClass("close");
  });
})(jQuery);


(function ($) {
  const currency = $('.currency');
  // console.log(currency);
  
})(jQuery);


